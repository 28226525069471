.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  margin-top: 80px;
}

.stat-item {
  flex: 1;
  margin: 0 10px;
}

.space {
  margin: 100px;
}

.count-up {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}
